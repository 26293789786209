import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import OrgExerciseMetricsStore from 'app/stores/OrgExerciseMetricsStore';

interface Metric {
  name: string;
  value: number;
}

export class OrgExerciseMetricsModel extends Model {
  public static readonly _store: OrgExerciseMetricsStore;

  @observable public id: string;
  @observable public metrics: Metric[];

  static fromJson(json: ModelJson): OrgExerciseMetricsModel {
    return this._fromJson(json) as OrgExerciseMetricsModel;
  }

  static getOrNew(id): OrgExerciseMetricsModel {
    return this._getOrNew(id) as OrgExerciseMetricsModel;
  }

  static get(id): OrgExerciseMetricsModel {
    return this._get(id) as OrgExerciseMetricsModel;
  }
}

export default OrgExerciseMetricsModel;
