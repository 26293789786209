import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem, OrgExerciseMetricsModel } from 'app/models';
import Store from 'app/stores/Store';

export class OrgExerciseMetricsStore extends Store<OrgExerciseMetricsModel> {
  @observable public alignMetrics = new ModelItem<OrgExerciseMetricsModel>(OrgExerciseMetricsModel);
  @observable public reflect360Metrics = new ModelItem<OrgExerciseMetricsModel>(
    OrgExerciseMetricsModel
  );
  @observable public teamMetrics = new ModelItem<OrgExerciseMetricsModel>(OrgExerciseMetricsModel);
  @observable public memberMetrics = new ModelItem<OrgExerciseMetricsModel>(
    OrgExerciseMetricsModel
  );

  constructor() {
    super();
    OrgExerciseMetricsModel.setStore(this);
  }

  public async loadAlignMetrics(orgId: number, params: Record<string, any>): Promise<void> {
    const url = ServerRouteHelper.api.organizations.exerciseMetrics(orgId);
    await this.alignMetrics.load(url, { type: 'align', filters: params });
  }

  public async loadReflect360Metrics(orgId: number, params: Record<string, any>): Promise<void> {
    const url = ServerRouteHelper.api.organizations.exerciseMetrics(orgId);
    await this.reflect360Metrics.load(url, { type: 'reflect360', filters: params });
  }

  public async loadTeamMetrics(orgId: number, params: Record<string, any>): Promise<void> {
    const url = ServerRouteHelper.api.organizations.teamMetrics(orgId);
    await this.teamMetrics.load(url, { type: 'team', filters: params });
  }

  public async loadMemberMetrics(orgId: number, params: Record<string, any>): Promise<void> {
    const url = ServerRouteHelper.api.organizations.memberMetrics(orgId);
    await this.memberMetrics.load(url, { type: 'member', filters: params });
  }
}

export default OrgExerciseMetricsStore;
