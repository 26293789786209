import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { OrganizationMemberListMetricsStore } from 'app/stores/OrganizationMemberListMetricsStore';

export class OrganizationMemberListMetricsModel extends Model {
  public static readonly _store: OrganizationMemberListMetricsStore;

  @observable public id: string;
  @observable public name: string;
  @observable public email: string;

  @observable public created_at: string;
  @observable public last_activity_at?: string;

  @observable public has_perspective: string;
  @observable public latest_perspective_activity_at: string;
  @observable public perspective_activity_count: number;
  @observable public latest_perspective_created_at: string;
  @observable public latest_perspective_pair_report_viewed_at: string;
  @observable public latest_perspective_team_report_viewed_at: string;
  @observable public latest_manager_playbook_report_viewed_at: string;

  @observable public has_align: string;
  @observable public latest_align_activity_at: string;
  @observable public align_activity_count: number;
  @observable public latest_align_created_at: string;
  @observable public latest_align_response_submitted_at: string;
  @observable public latest_align_report_shared_at: string;

  @observable public has_pulse: string;
  @observable public latest_pulse_activity_at: string;
  @observable public pulse_activity_count: number;
  @observable public latest_member_pulse_created_at: string;
  @observable public latest_team_pulse_created_at: string;
  @observable public latest_team_pulse_response_submitted_at: string;

  @observable public has_reflect360: string;
  @observable public reflect360_activity_count: number;
  @observable public latest_reflect360_activity_at: string;
  @observable public latest_reflect360_created_at: string;
  @observable public latest_reflect360_response_submitted_at: string;

  static fromJson(json: ModelJson): OrganizationMemberListMetricsModel {
    return this._fromJson(json) as OrganizationMemberListMetricsModel;
  }

  static getOrNew(id): OrganizationMemberListMetricsModel {
    return this._getOrNew(id) as OrganizationMemberListMetricsModel;
  }

  static get(id): OrganizationMemberListMetricsModel {
    return this._get(id) as OrganizationMemberListMetricsModel;
  }
}
