import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelList, PagingMetaModel } from 'app/models';
import { OrganizationMemberListMetricsModel } from 'app/models/OrganizationMemberListMetricsModel';
import Store from 'app/stores/Store';

export class OrganizationMemberListMetricsStore extends Store<OrganizationMemberListMetricsModel> {
  @observable public metrics = new ModelList<OrganizationMemberListMetricsModel>(
    OrganizationMemberListMetricsModel
  );

  @observable pageMeta: PagingMetaModel;

  constructor() {
    super();
    OrganizationMemberListMetricsModel.setStore(this);
  }

  public async loadMemberList(
    orgId: number,
    filters: Record<string, any>,
    pageNumber: number = null
  ): Promise<void> {
    const url = ServerRouteHelper.api.organizations.memberList(orgId);

    const params = { filters: filters };

    if (pageNumber) {
      params['page'] = pageNumber;
    }

    await this.metrics.load(url, params, {
      onResponse: (response) => {
        this.pageMeta = new PagingMetaModel(response.meta);
      },
    });
  }
}

export default OrganizationMemberListMetricsStore;
