import React, { Suspense } from 'react';

import { lazy } from '@loadable/component';
import { Switch } from 'react-router-dom';

import Loading from 'app/components/ui/Loading';
import Route from 'app/customRoute';

const AlignOverview = lazy(() => import('./AlignOverview'));
const PerspectiveOverview = lazy(() => import('./PerspectiveOverview'));
const TeamHabitOverview = lazy(() => import('./TeamHabitOverview'));
const MarketingOverview = lazy(() => import('./MarketingOverview'));
const Reflect360Overview = lazy(() => import('./Reflect360Overview'));
const OrgConfirmation = lazy(() => import('./Confirmation'));

export default (
  <Suspense fallback={<Loading />}>
    <Switch>
      <Route exact path="/organizations/overview" component={MarketingOverview} />
      <Route exact path="/organizations/confirmation" component={OrgConfirmation} />
      <Route exact path="/organizations/team-habits/overview" component={TeamHabitOverview} />
      <Route exact path="/organizations/:orgId?/aligns/overview" component={AlignOverview} />
      <Route
        exact
        path="/organizations/:orgId?/perspective/overview"
        component={PerspectiveOverview}
      />
      <Route exact path="/organizations/reflect360/overview" component={Reflect360Overview} />
    </Switch>
  </Suspense>
);
